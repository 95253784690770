import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import backButtonStyles from "./backbutton.module.scss"

export default (props) => {
  return (
    <Link
        className={backButtonStyles.backButton}
        to={props.destination}
        >
        <FontAwesomeIcon icon={faArrowLeft} /> 
    </Link>
  )
}