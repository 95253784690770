import React from "react"
import Layout from "../components/layout"
import Content from "../components/content"
import backpack from "../assets/backpack.svg"
import skillsStyles from "./skills.module.scss"
import BackButton from "../components/backbutton"
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SEO from "../components/seo"


const skillData = [
    {name:'Lens Studio', text: 'Using Lens Studio, powerful and immersive lenses for Snapchat can engage users with your brand.'},
    {name:'Spark AR Studio', text: 'I use Spark AR Studio to create beautiful effects for Instagram and Facebook.'},
    {name:'Animation', text: 'I have worked with animation libraries including Framer Motion and the native Javascript animation API.'},
    {name:'Adobe Illustrator', text: 'I am well-versed in vector Illustration, not only technically but also in terms of artistic principles such as colour, composition and shape.'},
    {name:'JavaScript', text: 'I have worked with JavaScript for over three years professionally, and have built numerous animations and websites with it.'},
    {name:'Blender', text: 'Using Blender, I can create animated photorealistic models for use in AR effects, as well as standalone 3D illustrations and animations.'},
    {name:'Adobe Photoshop', text: 'I am adept at using Photoshop to create photorealistic textures for 3D models.'}

]


const SkillInfo = (props) => {
    const skill = skillData[props.skill];
        return (
            <div className={skillsStyles.skillsInfo}>
                <FontAwesomeIcon icon={faArrowLeft} onClick={props.decrementSkill}/>
                <FontAwesomeIcon icon={faArrowRight} onClick={props.incrementSkill}/>

                <h2>{skill.name}</h2>
                <p>{skill.text}</p>
             </div>
        )
}

const  BackpackPart = props => (
    <use
        className={skillsStyles.backpackPart}
        href={`${backpack}#${props.idName}`}
    >
    </use>
)



export default class Skills extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          skill: 0
        };
      }


   incrementSkill = () => {
       if (this.state.skill >= skillData.length-1) {
            this.setState({skill: 0})
       }
       else {
           this.setState({skill: this.state.skill +1})
       }
   }

   decrementSkill = () => {
        if (this.state.skill <= 0) {
            this.setState({skill: skillData.length-1})
        }
        else {
            this.setState({skill: this.state.skill -1})
        }
}
   
   render(){
    return (
        <div className={skillsStyles.background}>
            <Layout>
                <SEO
                    title={"Phil Cohn - Skills"}
                    description={'Discover the technological skills of freelance AR creator Phil Cohn.'}
                    image={'/static/favicon.ico'}
                    article
                />
                <Content>
                    <div className={skillsStyles.skillsContainer}>
                        <div className={skillsStyles.topRow}>
                            <BackButton destination="/about/"/>
                            <h1>
                                My skills
                            </h1>
                        </div>
                        <div className={skillsStyles.skillsSelector}>
                            <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 301.6 410.54"
                            className={skillsStyles.backpack}>
                                
                                <BackpackPart idName='Layer_2-2' />
                                <BackpackPart idName='Layer_3' />
                                <BackpackPart idName='Layer_4' />
                                <BackpackPart idName='Layer_5' />
                                <BackpackPart idName='Layer_6' />
                                <BackpackPart idName='Layer_7' />
                                <BackpackPart idName='Layer_8' />
                                <BackpackPart idName='Layer_9' />
                                <BackpackPart idName='Layer_11' />
                                <BackpackPart idName='Layer_1-2' />
                                <BackpackPart idName='Layer_9' />
                                <BackpackPart idName='Layer_10' /> 
                                <use href={`${backpack}#Layour_1-2}`}/>
                            </svg>
                            <SkillInfo skill={this.state.skill} 
                                incrementSkill={this.incrementSkill} 
                                decrementSkill={this.decrementSkill}/>
                        </div>
                    </div>
                </Content>
            </Layout>
        </div> 
        
        )
   }            
}


